.user-table .user-row.selected {
  background-color: red;
}

.user-table .new-user {
  text-align: right;
}

.user-table .user-row.inactive .email {
  text-decoration: line-through;
}
