.files {
  padding: 4%;
  width: auto;
}

.files .full {
  grid-column-start: 1;
  grid-column-end: 13;
}

.files .directories {
  border: 3px solid grey;
  borderradius: 5px;
}

.files .directories .indent-level {
  margin-left: 40px;
}

.files .grid {
  display: grid;
}

.files .errors {
  padding: 40px;
}

.files .action-bar {
  margin-bottom: 20px;
}

.files .action-bar .ab-left {
  grid-column-start: 2;
  grid-column-end: 6;
}

.files .action-bar .ab-right {
  grid-column-start: 9;
  grid-column-end: 14;
}

.files .file.selectable:focus {
  outline: none;
}
