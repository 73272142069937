.mgmt-editor .actions {
  padding: 40px;
  text-align: center;
}

.mgmt-editor .actions button {
  margin: 10px;
}

.mgmt-editor .validation-error .msg {
  color: red;
  font-weight: bold;
  text-align: center;
  font-size: 1.25rem;
}
