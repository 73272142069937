.dir-name,
.file {
  cursor: pointer;
}

.dir-name.selected,
.file.selected {
  background-color: #eee;
}

.dir-name:hover,
.file:hover {
  text-decoration: underline;
}

.dir-name:focus {
  outline: none;
}

.dir.file-overhead {
  border: 2px dashed black;
  borderradius: 5px;
}

.dir .info {
  vertical-align: middle;
  margin-left: 5px;
}

.dir .icon {
  vertical-align: middle;
}

.dir .file {
  padding: 5px 10px;
}

.dir-actions {
  margin: 10px 0;
}

.dir-actions .left-icon {
  margin-right: 5px;
}
