.account-tabs {
  margin: 0 10%;
  width: 1200px;
}

.account {
  padding: 30px;
}

.account .left {
  grid-column-start: 1;
  grid-column-end: 7;
  margin-right: 30px;
}

.account .right {
  grid-column-start: 7;
  grid-column-end: 14;
}

.account .full {
  grid-column-start: 1;
  grid-column-end: 14;
}

.account .row {
  margin-top: 10px;
}

.account .loading {
  width: 300px;
  margin: 50px auto;
}

.account .submit button {
  margin-right: 30px;
}

.device-name {
  background: #eee;
  color: red;
  padding: 5px;
  borderradius: 5px;
}

.overridden {
  text-decoration: line-through;
}
