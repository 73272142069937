.support {
  padding: 4%;
  width: auto;
}

.support .full {
  text-align: center;
  grid-column-start: 1;
  grid-column-end: 13;
}

.support .guide-link .icon {
  vertical-align: middle;
  margin-right: 5px;
}
