.user-mgmt .view {
  grid-column-start: 1;
  grid-column-end: 7;
  padding: 30px;
}

.user-mgmt .edit {
  grid-column-start: 7;
  grid-column-end: 14;
  padding: 30px;
}
