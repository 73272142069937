.ob-selector.scopes {
  max-width: 500px;
}

.ob-selector.scopes .MuiSelect-select {
  /* need the list of active scopes to wrap to multiple lines */
  white-space: normal;
}

.scopes-option.Mui-selected {
  background-color: rgba(231, 231, 231, 0.7);
}
