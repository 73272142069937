.user-edit input {
  margin: 5px;
}

.user-edit .pw-field,
.user-edit .username-field,
.user-edit .email-field {
  width: 400px;
}

.user-edit .r-margin {
  margin-right: 20px;
}

.user-edit .line {
  margin-top: 5px;
  margin-bottom: 20px;
}
