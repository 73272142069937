.dir-actions.button .hidden-input {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  width: '100%';
}

.upload-loading {
  max-width: 500px;
}
