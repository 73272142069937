.api-docs .swagger-ui {
  flex-grow: 1;
}

.api-docs .swagger-ui .auth-wrapper {
  display: none;
}

.api-docs .code {
  font-family: courier;
  background: #eee;
  padding: 5px;
  borderradius: 5px;
}

.getting-started h1 {
  margin: 1rem 0 0 0.5rem;
}

.getting-started details {
  margin: 0.5rem 0 0 0.5rem;
}

.getting-started details > p {
  /* indent to the summmary text */
  margin-left: 1rem;
}

.getting-started details > ol {
  padding: 0 0 0 0.75rem;
}
.getting-started details > ol > li {
  padding: 0.25rem 0 0 0;
}

.getting-started details > details,
li {
  /* indent to the summary text */
  margin-left: 1.25rem;
}

.getting-started .example {
  display: grid;
  grid-template-columns: minmax(0, auto) 1fr;
  column-gap: 0.5rem;
  margin: 1rem 0 0 1rem;
}

.getting-started .example dl {
  margin: 0;
}

.getting-started .example dt {
  font-weight: bold;
}

.getting-started pre {
  margin: 0;
}

.getting-started code,
samp {
  display: block;
  /* separate the request from the response */
  padding: 0.5rem;
  font-family: monospace;
  background-color: hsl(0, 0%, 89%);
}

.getting-started code {
  /* forms the top of each snippet */
  borderradius: 0.5rem 0.5rem 0 0;
  color: hsl(300, 90%, 30%);
}

.getting-started samp {
  /* forms the bottom of each snippet */
  borderradius: 0 0 0.5rem 0.5rem;
  color: hsl(110, 60%, 35%);
}
