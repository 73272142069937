.audit {
  padding: 30px;
}

.audit .full {
  grid-column-start: 1;
  grid-column-end: 14;
}

.audit .query-options {
  text-align: center;
}

.audit .query-options,
.audit .query-options label {
  padding: 20px;
}

.audit .query-options .name {
  padding-right: 20px;
}
