.LoginView-error {
  color: red;
  margin: auto;
}

.nyserda .LoginView-center {
  margin-top: 80px;
  grid-column-start: 4;
  grid-column-end: 10;
  grid-row-start: 4;
  grid-row-end: 10;
}

.nyserda .LoginView-col {
  text-align: center;
}

.portal .login-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 150px;
  flex-wrap: wrap;
}

.portal .LoginView-center {
  color: #7f7f7f;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
}

.portal .login-button {
  color: #fff;
  background-color: #2ec4b6;
  font-family: 'Roboto';
}

@media (max-width: 600px) {
  .portal .login-image-container {
    display: none;
  }
}
