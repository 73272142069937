.grid-content {
  margin: 20px;
  padding: 10px 20px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
  borderradius: 5px;
}

.grid-content .row-helper {
  margin: 10px 0;
  display: flex;
  flex-direction: row;
}

.grid-content .fill {
  flex-grow: 99;
}
