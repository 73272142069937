.submissions {
  padding: 4%;
  width: auto;
}

.submissions .title {
  text-align: center;
  margin-bottom: 20px;
}

.submissions .full {
  grid-column-start: 1;
  grid-column-end: 13;
}
