body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root.nyserda {
  height: 100vh;
}

.App {
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .desktop-only-block-display {
    display: none;
  }
}

.App-Header {
  height: 6vh;
}

[class*='grid-container-'].App-body {
  overflow-y: auto;
}

@media only screen and (min-width: 768px) {
  .grid-container-med {
    display: grid;
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-template-columns: 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .desktop-only-block-display {
    display: flex;
  }

  .grid-container-lg {
    display: grid;
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-template-columns: 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333%;
  }

  .row {
    grid-column-start: 1;
    grid-column-end: 13;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .grid-container-xl {
    display: grid;
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-template-columns: 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333%;
  }
}

/* Extra large devices 1400 px and up*/
@media only screen and (min-width: 1400px) {
  .grid-container-xxl {
    display: grid;
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-template-columns: 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333%;
  }
}

:root {
  --active-color: #3f51b5;
  --error-color: #ff0000;
  --stale-color: #fb8c00;
}
