.AppHeader-center {
  width: 100%;
  justify-content: center;
}

.AppHeader-center div {
  text-align: center;
}

.AppHeader-left {
  width: 25%;
  text-align: left;
  margin-left: 15px;
}

.AppHeader-right {
  width: 25%;
  text-align: right;
  margin-right: 15px;
}

.AppHeader-right > div > * {
  margin-top: auto;
  margin-bottom: auto;
}

.AppHeader-logo img {
  display: none;
}

.AppHeader-orgName {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
}

@media only screen and (min-width: 992px) {
  .AppHeader-right div {
    display: flex;
    float: right;
    flex-direction: row;
  }
}

/* hide logo if it's not a large screen */
@media only screen and (min-width: 992px) {
  .AppHeader-left {
    display: flex;
  }

  .AppHeader-center {
    width: 50%;
    display: flex;
  }

  .AppHeader-logo {
    width: 10%;
    display: block;
  }

  .AppHeader-logo img {
    width: 100%;
    display: block;
  }
}
