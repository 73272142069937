.model-explorer {
  margin: 30px;
  width: auto !important;
  flex-grow: 1;
}

.model-explorer .nav {
  grid-column-start: 1;
  grid-column-end: 4;
}

.model-explorer .results {
  grid-column-start: 4;
  grid-column-end: 14;
}

.class-nav-root .title {
  margin-bottom: 20px;
}

.class-nav-root .class-items {
  padding-inline-start: 16px;
}

.class-items .class-items .class-items {
  list-style-type: none;
  padding-inline-start: 10px;
}

.class-items .multiselect-item {
  padding-inline-start: 4px;
}

.class-items .multiselect-checkbox {
  padding: 2px;
}

.class-nav-root .class-items.top .link {
  font-size: 1.25em;
  margin-left: -20px;
}

.class-nav-root .class-items .link {
  text-decoration: none;
  color: #333;
  width: 100%;
  padding: 2px 4px;
}

.class-nav-root .multiselect-name {
  text-transform: none;
  min-width: auto;
  padding: unset;
}

.class-nav-root .multiselect-name:hover {
  text-decoration: underline;
  color: blue;
}

.class-nav-root .class-items .link:hover {
  text-decoration: underline;
  color: blue;
}

.class-nav-root .class-items .link.active {
  background-color: rgb(231, 231, 231);
}

.class-viewer .leaf .name {
  margin-bottom: 40px;
}

.class-viewer .attr-grid,
.class-viewer .static-attr {
  margin-bottom: 20px;
}
