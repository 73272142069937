.submission-text.SUBMITTED {
  color: var(--stale-color);
}

.submission-text.APPROVED {
  color: green;
}

.submission-text.REJECTED,
.submission-text.DELETED {
  color: var(--error-color);
}
